<template>

    <a-form :model="formState" layout="vertical">
        <a-row :gutter="24">
            <a-col :span="12" :md="8" :lg="4">
                <a-form-item name="fecha_inicial" label="Fecha Inicial: ">
                    <a-date-picker v-model:value="formState.fecha_inicial" format="YYYY-MM-DD" class="w-100" placeholder="Selecciona la Fecha"/>
                </a-form-item>
            </a-col>
            <a-col :span="12" :md="8" :lg="4">
                <a-form-item name="fecha_final" label="Fecha Inicial: ">
                    <a-date-picker v-model:value="formState.fecha_final" format="YYYY-MM-DD" class="w-100" placeholder="Selecciona la Fecha"/>
                </a-form-item>
            </a-col>
            <a-col :span="12" :md="8" :lg="4">
                <a-form-item name="fecha_final" label="Documento ">
                    <a-input v-model:value="formState.texto_documento" placeholder="Serie o Correlativo"></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="12" :md="8" :lg="4">
                <a-form-item name="fecha_final" label="Proveedor: ">
                    <a-input v-model:value="formState.texto_proveedor" placeholder="RUC/DNI o Razón Social"></a-input>
                </a-form-item>
            </a-col>
            <a-col :span="24" :md="8" :lg="4"> 
                <a-form-item name="tipo_documento_id" label="Tipo de CPE: ">
                    <a-select v-model:value="formState.tipo_documento_id" mode="multiple" placeholder="Todos" max-tag-count="responsive">
                        <a-select-option v-for="(item, index) in catalogos.tipos_documento" :key="index" :value="item.codigo">{{item.descripcion}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="24" :md="8" :lg="4">
                <a-form-item name="tipo_estado_id" label="Estado de CPE: ">
                    <a-select v-model:value="formState.tipo_estado_id" mode="multiple" placeholder="Todos" max-tag-count="responsive">
                        <a-select-option v-for="(item, index) in catalogos.tipos_estado" :key="index" :value="item.codigo">{{item.descripcion}}</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row>
        <a-row :gutter="24">
            <a-col :span="24" class="d-flex justify-content-between" >
                <a-button type="primary" @click="filtrarDocumentos">Buscar</a-button>

                <!-- <div v-if="documentosPaginado.data">
                    <a-button type="primary" class="me-1">Exportar PDF</a-button>
                    <a-button type="primary">Exportar Excel</a-button>
                </div> -->
            </a-col>
        </a-row>
    </a-form>

</template>

<script>
    import { defineComponent, reactive, computed } from 'vue';
    import { useStore } from "vuex";

    export default defineComponent({
        setup(props, { emit }) {
            const store = useStore();

            const formState = reactive({
                fecha_inicial: null,
                fecha_final: null,
                texto_comprobante: null,
                texto_proveedor: null,
                tipo_documento_id: [],
                tipo_estado_id: [],
            });

            // getters
            const catalogos = computed(() => store.getters["catalogo/listaCatalogos"]);

            const documentosPaginado = computed(() => store.getters["bandeja_entrada/documentosPaginado"]);

            const filtrarDocumentos = () => {
                emit('filtrar', { ...formState });
            }

            return { formState, catalogos, documentosPaginado, filtrarDocumentos }
        }
    });
</script>
