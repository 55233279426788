<template>
    <div>

        <a-modal v-model:visible="verModalRezhazo" @cancel="cerrarModalRechazo" :maskClosable="false" :closable="false" width="500px" title="RECHAZAR DOCUMENTOS">

            <a-form ref="formRef" :model="formState.form" layout="vertical">
                <a-form-item name="codigo_rechazo_comercial" :rules="[{ required: true, message: 'Ingresa el Motivo del Rechazo.' }]">
                    <a-select v-model:value="formState.form.codigo_rechazo_comercial" mode="multiple" placeholder="Selecione el Motivo" max-tag-count="responsive">
                        <a-select-option v-for="(item, index) in catalogos.motivos_disconformidad" :key="index" :value="item.codigo">{{item.descripcion}}</a-select-option>
                    </a-select>
                </a-form-item> 
                <a-form-item name="motivo_rechazo_comercial" :rules="[{ required: true, message: 'Ingresa el Sustento del Rechazo.' }]">
                    <a-input v-model:value="formState.form.motivo_rechazo_comercial" placeholder="Ingresa el Sustento del Rechazo" />
                </a-form-item> 
            </a-form>
            <template #footer>
                <a-button key="back" @click="cerrarModalRechazo">Cerrar</a-button>
                <a-button key="submit" type="primary" :loading="isDeleting" @click="rechazar" danger>Rechazar</a-button>
            </template>
        </a-modal>

    </div>
</template>

<script>
    import { defineComponent, computed, reactive, ref } from 'vue';
    import { useStore } from "vuex";
    
    export default defineComponent({
        props: ['verModalRezhazo'],
        setup(props, { emit }) {
            const store = useStore();

            const formState = reactive({
                form: {
                    motivo_rechazo_comercial: null,
                    codigo_rechazo_comercial: []
                },
            });

            const formRef = ref();

            // getters
            const isDeleting = computed(() => store.getters["bandeja_entrada/isDeleting"]);
            const catalogos = computed(() => store.getters["catalogo/listaCatalogos"]);

            // actions

            const cerrarModalRechazo = () => {
                formRef.value.resetFields();
                emit('update:verModalRezhazo', false);
            }

            const rechazar = async () => {
                let { form } = formState;

                const validacion = await formRef.value.validateFields().then(response => true, error => false);

                if (validacion) {
                    emit('rechazar_documentos', form);
                    formRef.value.resetFields();
                };
                
            }

            const obtenerCatalogos = () => {
                store.dispatch('catalogo/obtenerCatalogos')
                    .then(response => { })
                    .catch(error => {
                        let message = error.response ? error.response.data.error : error.message; 
                        AntN.error('Catalogos', message);
                    });
            }

            obtenerCatalogos();
            
            return { formState, formRef, catalogos, isDeleting, cerrarModalRechazo, rechazar }
        }
    });
</script>