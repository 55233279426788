<template>
    <div>
        <a-divider orientation="left">Filtros</a-divider>

        <div class="m-3">
            <FiltradorTabla @filtrar="filtrarDocumentos" />
        </div>

        <a-table
            :row-selection="{selectedRowKeys: formState.documentosSeleccionado, ...rowSelection}"
            :columns="columnas"
            :data-source="documentosPaginado.data"
            :pagination="documentosPaginado.pag"
            :row-key="record => record.id"
            :loading="isLoading"
            @change="handleTableChange"
        >
            <template #headerCell="{ column }">
                <template v-if="column.dataIndex === 'acciones'">
                    <div class="text-end">{{ column.title }}</div>
                </template>
            </template>

            <template #bodyCell="{ column, text, record }">
                <template v-if="column.dataIndex === 'proveedor'">
                    <span :class="record.proveedor ? '' : 'text-danger' ">{{ record.proveedor_id }}</span>
                </template>

                <template v-if="column.dataIndex === 'empresa'">
                    <span >{{ record.empresa_id }}</span>
                </template>

                <template v-if="column.dataIndex === 'tipo_documento'">
                    <span>{{ record.tipo_documento.descripcion }} {{ record.serie }}</span> - <span>{{record.correlativo}}</span>
                </template>

                <template v-if="column.dataIndex === 'fecha'">
                    <span>{{ record.fecha }}</span>
                </template>

                <template v-if="column.dataIndex === 'total'">
                    <span>{{ record.total }}</span>
                </template>

                <template v-if="column.dataIndex === 'estado_comercial'">
                    <span> {{ record.data_estado_comercial.descripcion }}</span>
                </template>
                
                <template v-if="column.dataIndex === 'acciones'">
                    <div class="text-end btn-group">   
                        <a-button type="primary">CDR</a-button>
                        <a-button type="primary">XML</a-button>
                        <a-button type="primary">PDF</a-button>
                    </div>
                </template>
            </template>

            <template #summary>
                <a-table-summary>
                    <a-table-summary-row>
                        <a-table-summary-cell :index="0" class="pt-2">
                            <font-awesome-icon :icon="['fas', 'arrow-turn-down']" class="fa-rotate-180"/>
                        </a-table-summary-cell>

                        <a-table-summary-cell :index="1" :col-span="8">
                            <a-button type="primary" class="me-1" :disabled="formState.documentosSeleccionado.length == 0" @click="aceptarDocumentos">Aprobar</a-button>
                            <a-button type="primary" class="me-1" :disabled="formState.documentosSeleccionado.length == 0" @click="abrirModalRechazo" danger>Rechazar</a-button>
                        </a-table-summary-cell>
                    </a-table-summary-row>
                </a-table-summary>
            </template>
        </a-table>

        <ModalRechazo @rechazar_documentos="rechazarDocumentos" v-model:verModalRezhazo="verModalRezhazo"/>


    </div>
</template>

<script>
    import { defineComponent, computed, reactive, ref } from 'vue';
    import { useStore } from "vuex";
    import _ from 'lodash';

    import ModalRechazo from '@/components/tenant/bandeja_entrada/ModalRechazo';
    import FiltradorTabla from '@/components/tenant/bandeja_entrada/FiltradorTabla';

    const columnas = [
        { title: 'RUC EMISOR', dataIndex: 'proveedor', sorter: true},
        { title: 'RUC ADQUIRIENTE', dataIndex: 'empresa', sorter: true},
        { title: 'TIPO CPE', dataIndex: 'tipo_documento',},
        { title: 'FECHA CPE', dataIndex: 'fecha', sorter: true, },
        { title: 'MONTO CPE', dataIndex: 'total', sorter: true, },
        { title: 'FORMA DE PAGO', dataIndex: 'forma_pago', sorter: true, },
        { title: 'ESTADO TRIBUTARIO', dataIndex: 'descripcion_cpe', sorter: true, },
        { title: 'ESTADO COMERCIAL', dataIndex: 'estado_comercial', sorter: true, },
        { title: 'ACCIONES', dataIndex: 'acciones', },
    ];
    
    export default defineComponent({
        components: { FiltradorTabla, ModalRechazo },
        setup() {
            const store = useStore();

            const formState = reactive({
                documentosSeleccionado: [],
            });

            const verModalRezhazo = ref(false);

            // getters
            const isLoading = computed(() => store.getters["bandeja_entrada/isLoading"]);
            const listaDocumentos = computed(() => store.getters["bandeja_entrada/listaDocumentos"]);
            const documentosPaginado = computed(() => store.getters["bandeja_entrada/documentosPaginado"]);
            const catalogos = computed(() => store.getters["catalogo/listaCatalogos"]);

            // actions
            const handleTableChange = (pag, filter, sorter) => {
                let { data, filters } = documentosPaginado.value;

                store.dispatch('bandeja_entrada/obtenerDocumentosPaginado', { data, pag, filters, sorter });
            };
            
            const filtrarDocumentos = (filters) => {
                let consulta = { ...documentosPaginado.value };
                
                consulta.filters = filters;
                formState.documentosSeleccionado = [];
                store.dispatch('bandeja_entrada/obtenerDocumentosPaginado', consulta );
            }

            const enviarDocumentos = () => {
                let contador = 0;
                let data = [];

                _.forEach(formState.documentosSeleccionado, id => {
                    data.push(_.find(listaDocumentos.value, { id }));
                });

                console.log(JSON.stringify(data));
                
            }

            const aceptarDocumentos = () => {
                let contador = 0;
                let data = formState.documentosSeleccionado;

                _.forEach(data, id => {
                    contador++
                    let data_estado_comercial = catalogos.value.tipos_estado.find(item => item.codigo == constantes.CODIGO_ESTADO_ACEPTADO)
                    store.dispatch('bandeja_entrada/aceptarEstadoComercial', {id, data_estado_comercial, estado_comercial: constantes.CODIGO_ESTADO_ACEPTADO})
                        .then( response => {
                            AntN.notify('success', 'Bandeja de entrada', response.data.result);
                            formState.documentosSeleccionado = [];
                        })
                        .catch( error => {
                            let message = error.response ? error.response.data.error : error.message; 
                            AntN.error('Bandeja de entrada', message);
                        })
                        .finally(() => {
                            if(contador == data.length) {
                                store.commit('bandeja_entrada/establecerIsLoading', false);
                            }
                        });
                });
            }

            const rechazarDocumentos = async (form) => {
                let contador = 0;
                let data = formState.documentosSeleccionado;

                _.forEach(data, id => {
                    contador++

                    let data_estado_comercial = catalogos.value.tipos_estado.find(item => item.codigo == constantes.CODIGO_ESTADO_RECHAZADO)

                    store.dispatch('bandeja_entrada/rechazarEstadoComercial', {...form, id, data_estado_comercial, estado_comercial: constantes.CODIGO_ESTADO_RECHAZADO})
                        .then( response => {
                            AntN.notify('success', 'Bandeja de entrada', response.data.result);
                            formState.documentosSeleccionado = [];
                        })
                        .catch( error => {
                            let message = error.response ? error.response.data.error : error.message; 
                            AntN.error('Bandeja de entrada', message);
                        })
                        .finally(() => {
                            store.commit('bandeja_entrada/establecerIsDeleting', false);
                            if(contador == data.length) {
                                store.commit('bandeja_entrada/establecerIsLoading', false);
                                cerrarModalRechazo();
                            }
                        });
                });
            }

            const abrirModalRechazo = () => {
                verModalRezhazo.value = true;
            }

            const cerrarModalRechazo = () => {
                verModalRezhazo.value = false;
            }
            
            const rowSelection = {
                onChange: selectedRowKeys => {
                    formState.documentosSeleccionado = selectedRowKeys;
                },
                getCheckboxProps: record => ({
                    disabled: record.estado_envio === constantes.CODIGO_ESTADO_REGISTRADO ,
                }),
            };

            return { 
                formState, 
                isLoading, 
                abrirModalRechazo, 
                cerrarModalRechazo, 
                verModalRezhazo, 
                columnas, 
                handleTableChange, 
                rowSelection, 
                documentosPaginado, 
                aceptarDocumentos, 
                rechazarDocumentos, 
                filtrarDocumentos, 
                enviarDocumentos,
            }
        }
    });
</script>